require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/918e2615992df33fe39b1b962b019601/code.js', () => { 

var totalPrice, item;



BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'] = async function fn_918e2615992df33fe39b1b962b019601(Items) {
    totalPrice = 0;
  for (var item_index in Items) {
    item = Items[item_index];
    totalPrice = totalPrice + (item['quantity']) * (item['itemPrice']);
  }


  return totalPrice
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/approval/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['PurchaseRequest'] = (await Backendless.Data.of('PurchaseRequest').findById((___arguments.context.pageData['idr']), ));
  ___arguments.context.pageData['totalPrice'] = ((___arguments.context.pageData['PurchaseRequest'])['totalPrice']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('63c47e228ea25af9ba4fbc11708bc9b9', ((___arguments.context.pageData['PurchaseRequest'])['request']));

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/approval/components/b0fd991b3a9b1eaae884989d783ec432/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await Backendless.Data.of('PurchaseRequest').save( ({ 'objectId': (___arguments.context.pageData['idr']),'isApproved': true }) );
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6d75a4e5bf7e1a4373b3e557819d2777', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4846dd069ecfe5c692f2dca42044ec87', true);
  ___arguments.context.pageData['message'] = 'The purchase request was approved!';
  ___arguments.context.pageData['iconApprove'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/approval/components/99de61b97727099c065c99f10c1fdce1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await Backendless.Data.of('PurchaseRequest').save( ({ 'objectId': (___arguments.context.pageData['idr']),'isApproved': false }) );
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6d75a4e5bf7e1a4373b3e557819d2777', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4846dd069ecfe5c692f2dca42044ec87', true);
  ___arguments.context.pageData['message'] = 'The purchase request was rejected!';
  ___arguments.context.pageData['iconReject'] = true;

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/forTests/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['prices'] = ({ 'Pencils': 1,'Pens': 2,'Paper': 10,'Staplers': 20,'Monitor': 500,'Computer': 1000 });
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7205de446232789387153c1158ad7327'))['totalPrice'] = 0;
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7205de446232789387153c1158ad7327'))['status'] = 'Not saved';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('51ffb7449288ce7bfade950e807215b8', [({ 'checkboxState': false,'item': 'None','quantity': 1,'itemPrice': 0,'discription': '' })]);
  ___arguments.context.pageData['manager'] = (await Backendless.Data.of('Employee').findFirst());

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/forTests/components/0ec5dbf3efd908bbbb9519e1e47d2821/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      if (___arguments.value == 'None') {
    ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['itemPrice'] = 0;
  } else {
    ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['itemPrice'] = ((___arguments.context.pageData['prices'])[___arguments.value]);
  }
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/forTests/components/92acb704a23466977e6c58e8b4e36798/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).push(({ 'checkboxState': false,'item': 'None','quantity': 1,'itemPrice': 0,'discription': '' }));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    var list;



  return (((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).slice(-1)[0] == ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec'))

  },  
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/forTests/components/3f68ff56232318cd18461ce2dc15a57b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/forTests/components/7205de446232789387153c1158ad7327/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async onSubmit(___arguments) {
    var error, id, item, object;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getRTSubscriptions() { const subs = getGlobalEntitiesMap('subscriptions');subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`RT subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}


  var item_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'));
  for (var item_index in item_list) {
    item = item_list[item_index];
    if ((item['item']) == 'None' || (item['discription']) == '') {
      ___arguments.context.dataModel['status'] = 'Please fill in all fields';
      return false;
    }
  }
  try {
    ___arguments.context.dataModel['submitBtnDisabled'] = true;
    id = ((await Backendless.Data.of('PurchaseRequest').save( ({ 'request': (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).map(async item => {; return ({ 'item': (item['item']),'quantity': (item['quantity']),'itemPrice': (item['itemPrice']),'discription': (item['discription']) });}))),'totalPrice': (___arguments.context.dataModel['totalPrice']) }) ))['objectId']);
    await Backendless.Data.of('PurchaseRequest').setRelation(id, 'manager', [(___arguments.context.pageData['manager'])]);
    ___arguments.context.dataModel['status'] = 'Your request has been submitted';

    ;(function() {
      const callback = async () => {
          if ((___arguments.context.dataModel['totalPrice']) <= 200) {
        await Backendless.Data.of('PurchaseRequest').save( ({ 'objectId': id,'isApproved': true }) );
      }

      };

      const timerId = '';
      const timerDelay = 2000;

      runSetTimeout(timerId, callback, timerDelay)
    })()

    ;(function() {
      const callback = async object => {
          if (object['isApproved']) {
        ___arguments.context.dataModel['status'] = 'Request has been approved';
      } else {
        ___arguments.context.dataModel['status'] = 'Request has been rejected';
      }
      ___arguments.context.dataModel['submitBtnDisabled'] = false;
      getRTSubscriptions().remove(id);;

      };

      const rtHandlers = Backendless.Data.of('PurchaseRequest').rt();

      const whereClause = '';
      const options = [callback];

      if (whereClause) {
         options.unshift(whereClause);
      }

      rtHandlers.addUpdateListener.apply(rtHandlers, options);

      getRTSubscriptions().add(id, () => rtHandlers.removeUpdateListener(callback));

    })()

  } catch (error) {
    ___arguments.context.dataModel['status'] = (error['message']);

  }

  },  
  /* handler:onSubmit */
  /* content */
}))

define('./pages/forTests/components/b82345e9ba1c8c8e08e5cf8d9336e688/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('approval', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/forTests/components/d95d6cc598c4a7426764b78d53df4b96/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/forTests/components/8feb8a462029a4fa9ea204855fec32c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    var disabled, counter, item;


  console.log(String('Disabled State Logic ---- ') + String(___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['item']));
  disabled = false;
  if (!(___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['checkboxState'])) {
    counter = 0;
    var item_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'));
    for (var item_index in item_list) {
      item = item_list[item_index];
      if (!(item['checkboxState'])) {
        counter = (typeof counter == 'number' ? counter : 0) + 1;
      }
    }
    if (counter <= 1) {
      disabled = true;
    }
  }

  return disabled

  },  
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/forTests/components/f8596daa48c5f03f6991569839e55d47/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var item;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('51ffb7449288ce7bfade950e807215b8', (await asyncListFilter(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')), async (item) => {


   return (!(item['checkboxState']));
  })));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/forTests/components/f203c2242c68b80f9e334c397dd5b9d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      console.log((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/order/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
      ___arguments.context.pageData['prices'] = ({ 'Pencils': 1,'Pens': 2,'Paper': 10,'Staplers': 20,'Monitor': 500,'Computer': 1000 });
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7205de446232789387153c1158ad7327'))['totalPrice'] = 0;
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7205de446232789387153c1158ad7327'))['status'] = 'Not saved';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('51ffb7449288ce7bfade950e807215b8', [({ 'checkboxState': false,'item': 'None','quantity': 1,'itemPrice': 0,'description': '' })]);
  ___arguments.context.pageData['manager'] = (await Backendless.Data.of('Employee').findFirst());

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/order/components/0ec5dbf3efd908bbbb9519e1e47d2821/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      if (___arguments.value == 'None') {
    ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['itemPrice'] = 0;
  } else {
    ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['itemPrice'] = ((___arguments.context.pageData['prices'])[___arguments.value]);
  }
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/order/components/92acb704a23466977e6c58e8b4e36798/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).push(({ 'checkboxState': false,'item': 'None','quantity': 1,'itemPrice': 0,'description': '' }));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  onDisplayAssignment(___arguments) {
    var list;



  return (((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).slice(-1)[0] == ___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec'))

  },  
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/order/components/3f68ff56232318cd18461ce2dc15a57b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
      ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/order/components/7205de446232789387153c1158ad7327/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async onSubmit(___arguments) {
    var error, id, object, item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getRTSubscriptions() { const subs = getGlobalEntitiesMap('subscriptions');subs.map = subs.map || {};subs.remove = subs.remove || (subId => { if (subs.map[subId]) { subs.map[subId](); delete subs.map[subId];} });subs.add = subs.add || ((subId, callback) => {    if (subs.map[subId]) { throw new Error(`RT subscription with ID: "${subId}" already exists.`) }    subs.map[subId] = callback; });return subs;}


  var item_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'));
  for (var item_index in item_list) {
    item = item_list[item_index];
    if ((item['item']) == 'None' || (item['description']) == '') {
      ___arguments.context.dataModel['status'] = 'Please fill in all fields';
      return false;
    }
  }
  try {
    ___arguments.context.dataModel['submitBtnDisabled'] = true;
    id = ((await Backendless.Data.of('PurchaseRequest').save( ({ 'request': (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')).map(async item => {; return ({ 'item': (item['item']),'quantity': (item['quantity']),'itemPrice': (item['itemPrice']),'description': (item['description']) });}))),'totalPrice': (___arguments.context.dataModel['totalPrice']) }) ))['objectId']);
    await Backendless.Data.of('PurchaseRequest').setRelation(id, 'manager', [(___arguments.context.pageData['manager'])]);
    ___arguments.context.dataModel['status'] = 'Your request has been submitted';

    ;(function() {
      const callback = async () => {
          if ((___arguments.context.dataModel['totalPrice']) <= 200) {
        await Backendless.Data.of('PurchaseRequest').save( ({ 'objectId': id,'isApproved': true }) );
      }

      };

      const timerId = '';
      const timerDelay = 2000;

      runSetTimeout(timerId, callback, timerDelay)
    })()

    ;(function() {
      const callback = async object => {
          if (object['isApproved']) {
        ___arguments.context.dataModel['status'] = 'Request has been approved';
      } else {
        ___arguments.context.dataModel['status'] = 'Request has been rejected';
      }
      ___arguments.context.dataModel['submitBtnDisabled'] = false;
      getRTSubscriptions().remove(id);;

      };

      const rtHandlers = Backendless.Data.of('PurchaseRequest').rt();

      const whereClause = '';
      const options = [callback];

      if (whereClause) {
         options.unshift(whereClause);
      }

      rtHandlers.addUpdateListener.apply(rtHandlers, options);

      getRTSubscriptions().add(id, () => rtHandlers.removeUpdateListener(callback));

    })()

  } catch (error) {
    ___arguments.context.dataModel['status'] = (error['message']);

  }

  },  
  /* handler:onSubmit */
  /* content */
}))

define('./pages/order/components/b82345e9ba1c8c8e08e5cf8d9336e688/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('approval', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/order/components/d95d6cc598c4a7426764b78d53df4b96/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/order/components/8feb8a462029a4fa9ea204855fec32c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  onDisabledStateAssignment(___arguments) {
    var disabled, counter, item;


  disabled = false;
  if (!(___arguments.context.getComponentDataStoreByUid('e5481cf63fe1617cc8ee95836f97bbec')['checkboxState'])) {
    counter = 0;
    var item_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'));
    for (var item_index in item_list) {
      item = item_list[item_index];
      if (!(item['checkboxState'])) {
        counter = (typeof counter == 'number' ? counter : 0) + 1;
      }
    }
    if (counter <= 1) {
      disabled = true;
    }
  }

  return disabled

  },  
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/order/components/f8596daa48c5f03f6991569839e55d47/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var item;

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('51ffb7449288ce7bfade950e807215b8', (await Promise.all((await asyncListFilter(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8')), async (item) => {


   return (!(item['checkboxState']));
  })).map(async item => {; return ({ 'checkboxState': (item['checkboxState']),'item': (item['item']),'quantity': (item['quantity']),'itemPrice': (item['itemPrice']),'description': (item['description']) });}))));
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['status'] = 'Not saved';
  ___arguments.context.getComponentDataStoreByUid('7205de446232789387153c1158ad7327')['totalPrice'] = (await BackendlessUI.Functions.Custom['fn_918e2615992df33fe39b1b962b019601'](((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('51ffb7449288ce7bfade950e807215b8'))));

  },  
  /* handler:onClick */
  /* content */
}))

